










import { Component, Vue } from 'vue-property-decorator'
import { Admin } from '@/api'

@Component({
  components: {
    UserInfoCard: () => import('@/components/Profile/UserInfoCard.vue')
  }
})
export default class UserGeneralInformation extends Vue {
  user: User = {} as User

  async mounted() {
    this.user = await Admin.getUserById(this.$route.params.id)
  }
}
